import React, { useEffect } from 'react'
import { navigate } from 'gatsby'

export const IndexPage: React.FC = () => {

  useEffect(() => {
    navigate(`/blog`)
  }, [])

  return null

}

export default IndexPage